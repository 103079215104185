import Vue from 'vue'
import VueRouter from 'vue-router'
// import {mapMutations} from 'vuex'
Vue.use(VueRouter)

const routes = [

  // 首页
  {
    path: "/",
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import('@/views/Home/index'),
        meta: { title: "首页" },
      },
      // 个人端
      // 个人隐私
      {
        path: "/privacy",
        name: "Privacy",
        component: () => import('@/views/privacy/index'),
        meta: { title: "个人隐私" }
      },
      // 职位
      {
        path: "/position",
        name: "Position",
        component: () => import('@/views/position/index'),
        meta: { title: "职位" }
      },
      // 企业
      {
        path: "/enterprise",
        name: "Enterprise",
        component: () => import('@/views/enterprise/index'),
        meta: { title: "企业" }
      },
      // 通知中心
      {
        path: "/notification",
        name: "Notification",
        component: () => import('@/views/notification/index'),
        meta: { title: "通知中心" }
      },
      // 职位详情
      {
        path: "/position-details",
        name: "Position-details",
        component: () => import('@/views/position/Position-details/index'),
        meta: { title: "职位详情" }
      },
      // 企业详情
      {
        path: "/company-details",
        name: "Company-details",
        component: () => import('@/views/enterprise/Company-details/index'),
        meta: { title: "企业详情" }
      },
      // 个人中心
      {
        path: "/personal-center",
        name: "Personal-center",
        component: () => import('@/views/Personal-center/index'),
        meta: { title: "个人中心" }
      },
      // 申请成为精英
      {
        path: "/elite",
        name: "Elite",
        component: () => import('@/views/elite/index'),
        meta: { title: "申请成为精英" }
      },
      // 切换成为招聘者
      {
        path: "/recruiter",
        name: "Recruiter",
        component: () => import('@/views/recruiter/index'),
        meta: { title: "切换为招聘者" }
      },
      // 求职反馈
      {
        path: "/jobHunting",
        name: "JobHunting",
        component: () => import('@/views/jobHunting/index'),
        meta: { title: "求职反馈" }
      },
      // 在线互动
      {
        path: "/interaction",
        name: "Interaction",
        component: () => import('@/views/interaction/index'),
        meta: { title: "在线互动" }
      },
      // 简历页面
      {
        path: "/my-resume",
        name: "My-resume",
        component: () => import('@/views/My-resume/index'),
        children: [
          // 我的简历
          {
            path: "/my-resume",
            name: "My-Detail",
            component: () => import('@/views/My-resume/detail/index'),
            meta: { title: "我的简历", index: 1, titleText: '简历' }
          },
          // 基本信息
          {
            path: "/my-resume/basic",
            name: "My-Basic-Information",
            component: () => import('@/views/My-resume/Basic-Information/index'),
            meta: { title: "基本信息", index: 2, titleText: '简历' }
          },
          // 个人优势
          {
            path: "/my-resume/advantage",
            name: "My-Advantage",
            component: () => import('@/views/My-resume/advantage/index'),
            meta: { title: "个人优势", index: 3, titleText: '简历' }
          },
          // 求职意向
          {
            path: "/my-resume/objective",
            name: "My-Objective",
            component: () => import('@/views/My-resume/objective/index'),
            meta: { title: "求职意向", index: 4, titleText: '简历' }
          },
          // 工作经历
          {
            path: "/my-resume/experience",
            name: "My-Experience",
            component: () => import('@/views/My-resume/experience/index'),
            meta: { title: "工作经历", index: 5, titleText: '简历' }
          },
          // 教育经历
          {
            path: "/my-resume/educational",
            name: "My-Educational",
            component: () => import('@/views/My-resume/educational/index'),
            meta: { title: "教育经历", index: 6, titleText: '简历' }
          },
          // 资格证书
          {
            path: "/my-resume/certificate",
            name: "My-Certificate",
            component: () => import('@/views/My-resume/certificate/index'),
            meta: { title: "资格证书", index: 7, titleText: '简历' }
          }
        ]
      },
      // 个人账号与绑定
      {
        path: "/account",
        name: "UserAccount",
        component: () => import('@/views/account/index'),
        meta: { title: "个人账号与绑定" }
      },
      {
        path:"/protocol",
        name:"Protocol",
        component:()=>import("@/views/protocol/index"),
        meta:{title:"使用与帮助"}
      }
    ]
  },
  // 网站关闭也
  {
    path:"/website",
    name:"website",
    component: () => import('@/views/website/index'),
  },
  // 404页面
  {
    path:"/notFont",
    name:"notFont",
    component: () => import('@/views/notFont/index'),
  },
  // 登录页面
  {
    path: '/login',
    name: "Login",
    component: () => import('@/views/login/index'),
    hidden: true
  },
  // 忘记密码
  {
    path: "/forget",
    name: "Forget",
    component: () => import('@/views/forget/index')
  },
  // layout布局,企业端
  {
    path: '/layout',
    name: 'layout',
    component: resolve => require(['@/views/layout/index'], resolve),
    children: [
      {
        // 首页
        path: '/layout',
        name: 'home',
        component: resolve => require(['@/views/layout/home/index'], resolve),
        meta: { title: '首页' }
      },
      {
        // 个人中心
        path: '/layout/userInfo',
        name: 'userInfo',
        component: resolve => require(['@/views/layout/userInfo/index'], resolve),
        meta: { title: '个人中心' }
      },
      {
        // 职位管理发布
        path: '/layout/position',
        name: 'position',
        component: resolve => require(['@/views/layout/position/index'], resolve),
        meta: { title: '职位管理' }
      },
      {
        // 职位管理table
        path: '/layout/positionRelease',
        name: 'positionRelease',
        component: resolve => require(['@/views/layout/positionRelease/index'], resolve),
        meta: { title: '职位管理' }
      },
      // 人才推荐
      {
        path: "/layout/recommend",
        name: 'recommend',
        component: resolve => require(['@/views/layout/recommend/index'], resolve),
        meta: { title: '人才推荐' }
      },
      // 在线互动
      {
        path: "/layout/interactive",
        name: 'interactive',
        component: resolve => require(['@/views/layout/interactive/index'], resolve),
        meta: { title: '在线互动' }
      },
      // 人才管理
      {
        path: "/layout/manage",
        name: 'manage',
        component: resolve => require(['@/views/layout/manage/index'], resolve),
        meta: { title: '人才管理' }
      },
      // 面试管理
      {
        path: "/layout/interview",
        name: 'interview',
        component: resolve => require(['@/views/layout/interview/index'], resolve),
        meta: { title: '面试管理' }
      },
      // 通知中心
      {
        path: "/layout/notice",
        name: 'notice',
        component: resolve => require(['@/views/layout/notice/index'], resolve),
        meta: { title: '通知中心' }
      },
      // 账户资产
      {
        path: "/layout/assets",
        name: 'assets',
        component: resolve => require(['@/views/layout/assets/index'], resolve),
        meta: { title: '账户资产' }
      },
      // 升级vip
      {
        path: "/layout/vip",
        name: 'vip',
        component: resolve => require(['@/views/layout/vip/index'], resolve),
        meta: { title: '升级vip' }
      },
      // 名企大厂
      {
        path: "/layout/enterprises",
        name: "enterprises",
        component: resolve => require(['@/views/layout/enterprises/index'], resolve),
        meta: { title: '名企大厂' }
      },
      // 账号设置
      {
        path: "/layout/account",
        name: "account",
        component: resolve => require(['@/views/layout/account/index'], resolve),
        meta: { title: '账号设置' }
      },
      // 人才在线简历
      {
        path: "/layout/mangeCv",
        name: "mangeCv",
        component: resolve => require(['@/views/layout/mangeCv/index'], resolve),
        meta: { title: '人才管理' }

      },
      // 企业基本信息,后期对接到注册企业时第一次跳转到这个页面
      {
        path: "/layout/corporate-information",
        name: "CorporateInformation",
        component: resolve => require(['@/views/layout/corporateInformation/index'], resolve),
        meta: { title: '企业基本信息' }

      },
      {
        path: "/layout/activity",
        name: "Activity",
        component: resolve => require(['@/views/layout/activity/index'], resolve),
        meta: { title: '活动详情' }
      }
    ]
  },

  { path: '*', redirect: '/notFont', hidden: true }
]

const router = new VueRouter({
  routes
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 通过路由拦截器退出登录,每次路由跳转都会通过这个拦截器
// 在这里判断是否登录，如果登录了则进行跳转，没登陆则进行登录
router.beforeEach((to, from, next) => {
  let path = to.path
  console.log(path)
  // 如果即将跳转的路由等于这些路由则直接放行
  if (path == '/login' || path == "/" || path == '/position' || path=='/protocol' || path=="notFont" || path == '/enterprise' || path == "/Company-details" || path == '/position-details' ||path == '/Position-details' || path=="/forget" || path=="/website") {
    next()
  } else {
    // 如果没有登录的情况让它重定向到登录页面
    // 判断是否有token
    // 如果登录了让它直接进行下一步
    // 获取token
    let token = localStorage.getItem('accessToken')
    if (!token) {
      next('/login?type=1')
      return
    }
    next()
  }
})
export default router
