import Vue from 'vue'
import Vuex from 'vuex'
// 使用element-ui Message做消息提醒
import {
	Message
} from 'element-ui';
// 引入路由
import router from '@/router'
Vue.use(Vuex)

const store = new Vuex.Store({
	// 存放状态
	state: {
		userInfo: {}, //用户信息
		accessToken: "", //访问令牌
		sig: {}, //腾讯im
		userDetail: {}, //用户详细信息
		entDetail: {}, //企业详情
		setObj: {}
	},
	// store的计算属性
	getters: {
		// 是否登录
		hasLogin(state) {
			return !!state.accessToken //取反两次把原数据转换成布尔类型，有值为true
		}
	},
	// 更新store的状态
	mutations: {
		// 系统配置
		get_setting(state, setObj) {
			state.setObj = setObj
			localStorage.setItem("setObj", JSON.stringify(setObj))
		},
		// 企业详情
		entDetail(state, detailObj) {
			state.entDetail = detailObj
			localStorage.setItem("entDetail", JSON.stringify(detailObj))
		},
		// 用户具体信息
		SET_USER(state, user) {
			state.userDetail = user
			localStorage.setItem("userDetail", JSON.stringify(user))
		},
		// 更新腾讯im
		SET_SIG: (state, sig) => {
			state.sig = sig
			localStorage.setItem("sig", JSON.stringify(sig))
		},
		// 更新数据
		setState(state, obj) {
			for (let key in obj) {
				// 每个对象的的key作为状态名，value作为状态值
				state[key] = obj[key]
			}
		},
		// 更新用户登录状态
		setToken(state, data) {

			// 解构属性
			const {
				userInfo,
				access_token
			} = data
			// 状态赋值保存
			if (userInfo) {
				state.userInfo = userInfo
				localStorage.setItem("userInfo", JSON.stringify(userInfo))
			}
			if (access_token) {
				state.accessToken = access_token
				localStorage.setItem("accessToken", access_token)
			}
		},
		// 判断过期时间
		expireToken(state) {

			if (Object.keys(state.userInfo).length == 0) {
				return
			}
			var timestamp = Date.parse(new Date()) / 1000
			if (timestamp > state.userInfo.expire_time) {
				state.userInfo = {}
				state.accessToken = ""
				state.userDetail = {}
				state.sig = {}
				localStorage.removeItem("userInfo")
				localStorage.removeItem("accessToken")
				localStorage.removeItem("userDetail")
				localStorage.removeItem("sig")
				Message({
					message: '您的登录已过期',
					type: 'error',
					duration: 5 * 1000
				})

				// 登录过期跳转至登录页面
				router.push({
					path: '/login',
					query: {
						type: 1
					}
				})
			}

		},
		// 退出登录
		loginOut(state) {
			state.userInfo = {}
			state.accessToken = ""
			state.userDetail = {}
			state.sig = {}
			localStorage.removeItem("userInfo")
			localStorage.removeItem("accessToken")
			localStorage.removeItem("userDetail")
			localStorage.removeItem("sig")
			// hyz add
			localStorage.removeItem("layoutArr")
			localStorage.removeItem("oldSchool")
			localStorage.removeItem("entDetail")
			localStorage.removeItem("name")
			// 退出登录回到首页
			router.push({
				path: '/'
			})
		},
		// 清空数据
		increment(state) {
			state.userInfo = {}
			state.accessToken = ""
			state.userDetail = {}
			state.sig = {}
			localStorage.removeItem("userInfo")
			localStorage.removeItem("accessToken")
			localStorage.removeItem("userDetail")
			localStorage.removeItem("sig")
			// hyz add
			localStorage.removeItem("layoutArr")
			localStorage.removeItem("oldSchool")
			localStorage.removeItem("entDetail")
			localStorage.removeItem("name")
			// 登录过期跳转至登录页面
			router.push({
				path: '/login',
				query: {
					type: 1
				}
			})
		}
	},
	actions: {
		increment(context) {
			context.commit('increment')
		}
	}
})
// 导出store实例
export default store
