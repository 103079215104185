import request from '@/util/request'
// 系统相关的数据接口
export default {
    settings(){
        return request({
            url:"/api/common/getSite",
            method:"get"
        })
    },
     // 获取默认客服
     getDefaultKefu(){
        return request({
            url:"/api/common/getDefaultKefu",
            method:'get'
        })
    },
    // 设置广告点击
    advertClick(position_id){
        return request({
            url:`/api/operate.advert/click?position_id=${position_id}`,
            method:'get'
        })
    },
    // 获取活动详情
    activityDetail(query){
        return request({
            url:"/api/operate.activity/detail",
            method:"post",
            data:{...query}
        })
    }
   
}